import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { authConfig } from "../../../api-config/authConfig"

const initialState = {
    loading: false,
    getLoading: false,
    getStaffById: [],
    superAdminStaffs: [],
    error: ""
}

const getSuperAdminStaff = createAsyncThunk("api/getSuperAdminStaff", async () => {
    return await authConfig.get("/list_user_staff_admin").then((response) => {
        return response.data
    }).catch((e) => {
        console.log("error", e)
    })
})
// for search filter by sreeraj 15-4-24
const filteredSuperAdminStaff = createAsyncThunk("api/getSuperAdminStaff", async ({ status, staff_role, user_name }) => {
    const queryParams = new URLSearchParams({ status, staff_role, user_name }).toString()
    try {
        const response = await authConfig.get(`/list_user_staff_admin?${queryParams}`)
        return response.data
    } catch (error) {
        console.log("error", error)
        throw error
    }
})

const getSuperAdminStaffById = createAsyncThunk("api/getSuperAdminStaffById", async (formData) => {
    return await authConfig.post('/deatils_view_admin_users', formData).then((response) => {
        return response.data
    }).catch((e) => {
        console.log(e.error)
    })
})

const getSuperAdminStaffSlice = createSlice({
    name: "getSuperAdminStaffSlice",
    initialState,
    extraReducers: (builder) => {
        builder.
            addCase(getSuperAdminStaff.pending, (state) => {
                state.loading = true
            })
            .addCase(getSuperAdminStaff.fulfilled, (state, { payload }) => {
                state.superAdminStaffs = payload
                state.loading = false
            })
            .addCase(getSuperAdminStaff.rejected, (state) => {
                state.error = "Something went wrong!"
                state.loading = false
            }).
            addCase(getSuperAdminStaffById.pending, (state) => {
                state.getLoading = true
            })
            .addCase(getSuperAdminStaffById.fulfilled, (state, { payload }) => {
                state.getStaffById = payload
                state.getLoading = false
            })
            .addCase(getSuperAdminStaffById.rejected, (state) => {
                state.error = "Something went wrong!"
                state.getLoading = false
            })
    }
})

// const PermissionSlice = createSlice({
//     name:"PermissionSlice",
//     initialState,
//     extraReducers: (builder) => {
//         builder.
//         addCase(getRoles.pending, (state) => {
//             state.loading = true
//         })
//         .addCase(getRoles.fulfilled, (state, {payload}) => {
//             state.roles = payload
//             state.loading = false
//         })
//         .addCase(getRoles.rejected, (state) => {
//             state.error = "Something went wrong"
//             state.loading = false
//         })
//     }
// })

export { getSuperAdminStaff, getSuperAdminStaffById, filteredSuperAdminStaff }
export default getSuperAdminStaffSlice.reducer